import Shared from "shared/ui";

export const MaintenanceNoticePage = () => {
  return (
    <Shared.VStack align="center" justify="center" style={{ padding: 12 }}>
      <Shared.HStack align="center" justify="start" fullWidth>
        <Shared.Icon name="logo" size={40} />
      </Shared.HStack>

      <Shared.VStack
        align="center"
        justify="center"
        gap={12}
        style={{ height: "63dvh" }}
      >
        <Shared.Icon name="notice" size={42} />

        <h2 style={{ fontSize: 24 }}>
          <b>서비스 점검 안내</b>
        </h2>

        <p style={{ textAlign: "center", marginTop: 8, fontSize: 15 }}>
          안정적인 서비스 제공을 위해 사이트 점검 중입니다. <br />
          최대한 빠른 시간 내에 점검을 완료하겠습니다.
        </p>

        <Shared.VStack style={{ marginTop: 32, textAlign: "center" }}>
          <b style={{ color: "#6457d9", fontSize: 18 }}>
            {" "}
            2월 19일 오후 11시 - 2월 20일 오전 4시
          </b>

          <span style={{ textAlign: "center", color: "#6457d9" }}>
            (작업 완료 시 즉시 오픈)
          </span>
        </Shared.VStack>
      </Shared.VStack>
    </Shared.VStack>
  );
};
