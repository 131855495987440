/* eslint-disable no-param-reassign */
import axios from "axios";
import { api } from "shared/constants/api";
import type { APIAxiosError } from "shared/types/server";
import {
  errorHandlers,
  isAuthRelatedUrl,
  updateRetryConfig,
} from "./lib/axiosResponseErrorHandler";
import { getAxiosRequestHeaders } from "./lib/getAxiosRequestHeader";
import { handleDuplicatedRequest } from "./lib/handleDuplicatedRequest";

// Axios Instance
const instance = axios.create({
  baseURL: process.env.REACT_APP_REQUEST_DOMAIN,
  withCredentials: true,
  timeout: 40000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
  },
});

// 요청 인터셉터 설정
function setupRequestInterceptor() {
  instance.interceptors.request.use(
    // 📌 onFulfilled
    config => {
      if (config.method === "post") {
        handleDuplicatedRequest({
          protectedUrls: [
            api.users.refresh,
            api.users.logout,
            api.users.keepLogin,
          ],
          config,
        });
      }

      if (config.headers) {
        config.headers = {
          ...config.headers,
          ...getAxiosRequestHeaders(),
        };
      }

      return config;
    },

    // 📌 onRejected
    error => Promise.reject(error)
  );
}

// 응답 인터셉터 설정
function setupResponseInterceptor() {
  let authErrorCount = 0;

  instance.interceptors.response.use(
    // 📌 onFulfilled
    response => {
      if (isAuthRelatedUrl(response.config.url)) {
        authErrorCount = 0;
      }
      return response;
    },

    // 📌 onRejected
    async (error: APIAxiosError) => {
      // showAssert.group(`인증에러 ${error.config.url}`, () => {
      //   console.log(error);
      //   console.log("authErrorCount", authErrorCount);
      //   console.log(`isKeepLogin`, isKeepLogin());
      //   console.log(`accessToken`, validateTokenTime("accessToken"));
      //   console.log(`refreshToken`, validateTokenTime("refreshToken"));
      // });

      const errorStatus = error.response?.status;

      // 중복 요청 에러 처리
      const duplicateRequestErrorResult = errorHandlers.duplicateRequest(error);
      if (duplicateRequestErrorResult) return duplicateRequestErrorResult;

      // 재시도 횟수 확인
      const { currentRetries, currentDelay } = updateRetryConfig(error);
      const overRetriesResult = errorHandlers.overRetries(
        error,
        currentRetries
      );
      if (overRetriesResult) return overRetriesResult;

      // 새로운 Promise를 반환하여 currentDelay초 후 재시도 로직 실행
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          // const networkErrorResult = errorHandlers.networkError(error);
          // if (networkErrorResult) return networkErrorResult;

          const tokenErrorResult = errorHandlers.tokenRenewalError(error);
          if (tokenErrorResult) return tokenErrorResult;

          if (errorStatus === 401) {
            authErrorCount += 1;
            return errorHandlers.authError(error, reject, authErrorCount);
          }

          return reject(error);
        }, currentDelay);
      });
    }
  );
}

// 인터셉터 설정 함수
function setupAxiosInterceptors() {
  setupRequestInterceptor();
  setupResponseInterceptor();
}

setupAxiosInterceptors();

export { instance };
