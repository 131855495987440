import { RegisterBoardLayout } from "@components/zLegacyLayouts/Layouts/RegisterBoardLayout";
import { MaintenanceNoticePage } from "pages/help/maintenance-notice";
import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { ApplyCooperationRoutes } from "./ApplyCooperationRoutes";
import { CheckoutRoutes } from "./CheckoutRoutes";
import { FindRoutes } from "./FindRoutes";
import { HelpFakeRoutes } from "./HelpFakeRoutes";
import { HelpNoticeRoutes } from "./HelpNoticeRoutes";
import { HelpQnaRoutes } from "./HelpQnaRoutes";
import { HelpReportRoutes } from "./HelpReportRoutes";
import { IllegalDealRoutes } from "./IllegalDealRoutes";
import { MyshopBuyTabRoutes } from "./MyshopBuyTabRoutes";
import { MyshopInfoRoutes } from "./MyshopInfoRoutes";
import { MyshopPurchasesRoutes } from "./MyshopPurchasesRoutes";
import { MyshopSaleTabRoutes } from "./MyshopSalesRoutes";
import { SignupRoutes } from "./SignUpRoutes";
import { SigninRoutes } from "./SigninRoutes";
import { TalkRoutes } from "./TalkRoutes";
import { TopNavRoutes } from "./TopNavRoutes";
import { WishesRoutes } from "./WishesRoutes";

const UsedPriceModel = lazy(
  () => import("../../legacyPages/UsedPriceModel.view")
);
const CartPage = lazy(() =>
  import("pages/cart").then(module => ({
    default: module.CartPage,
  }))
);

const Category = lazy(() => import("../../legacyPages/Category.view"));
const MyPage = lazy(() =>
  import("pages/myshop").then(module => ({
    default: module.MyPage,
  }))
);
const Search = lazy(() => import("../../legacyPages/Search.view"));
const SearchMatch = lazy(() =>
  import("../../legacyPages/SearchMatch.view").then(module => ({
    default: module.SearchMatch,
  }))
);
const TermsOfService = lazy(() =>
  import("../../legacyPages/TermsOfService.view").then(module => ({
    default: module.TermsOfService,
  }))
);
const RecentlySaw = lazy(() =>
  import("../../legacyPages/RecentlySaw.view").then(module => ({
    default: module.RecentlySaw,
  }))
);
const AboutUs = lazy(() =>
  import("../../legacyPages/AboutUs.view").then(module => ({
    default: module.AboutUs,
  }))
);
const CompanyInfo = lazy(() =>
  import("../../legacyPages/CompanyInfo.view").then(module => ({
    default: module.CompanyInfo,
  }))
);
const PrivacyPolicy = lazy(() =>
  import("../../legacyPages/PrivacyPolicy.view").then(module => ({
    default: module.PrivacyPolicy,
  }))
);
const MiniShop = lazy(() =>
  import("../../legacyPages/MiniShop.view").then(module => ({
    default: module.MiniShop,
  }))
);
const ProductDetailPage = lazy(() =>
  import("pages/products/detail").then(module => ({
    default: module.ProductDetailPage,
  }))
);
const NewSearchResult = lazy(() =>
  import("../../legacyPages/NewSearchResult.view").then(module => ({
    default: module.NewSearchResult,
  }))
);
const SellerInfo = lazy(() =>
  import("../../legacyPages/SellerInfo.view").then(module => ({
    default: module.SellerInfo,
  }))
);
const ProductReviewRegister = lazy(() =>
  import("../../modules/boards/Review/ProductReviewRegister.view").then(
    module => ({
      default: module.ProductReviewRegister,
    })
  )
);
const NonMemberOrderList = lazy(() =>
  import("pages/non-member-orders").then(module => ({
    default: module.NonMemberOrders,
  }))
);
const FaqPage = lazy(() =>
  import("pages/help/faq").then(module => ({
    default: module.FaqPage,
  }))
);
const FaqDetailPage = lazy(() =>
  import("pages/help/faq").then(module => ({
    default: module.FaqDetailPage,
  }))
);
const FraudCasePage = lazy(() =>
  import("pages/help/fraud-cases").then(module => ({
    default: module.FraudCasePage,
  }))
);
const NeedChangePassword = lazy(() =>
  import("pages/auth").then(module => ({
    default: module.NeedChangePasswordPage,
  }))
);

const SellerGuidePage = lazy(() =>
  import("pages/help/seller-guide").then(module => ({
    default: module.SellerGuidePage,
  }))
);

const ProductEditPage = lazy(() =>
  import("pages/products/edit").then(module => ({
    default: module.ProductEditPage,
  }))
);

const ProductRegisterPage = lazy(() =>
  import("pages/products/resister").then(module => ({
    default: module.ProductRegisterPage,
  }))
);

const NotifyListPage = lazy(() =>
  import("pages/notify").then(module => ({
    default: module.NotifyListPage,
  }))
);

export const RouteList = () => {
  return (
    <Routes>
      <Route
        path="/maintenance"
        element={
          <Suspense fallback={null}>
            <MaintenanceNoticePage />
          </Suspense>
        }
      />
      <Route
        path="/used-price/model-price/:modelCode"
        element={
          <Suspense fallback={null}>
            <UsedPriceModel />
          </Suspense>
        }
      />
      <Route
        path="/menu/"
        element={
          <Suspense fallback={null}>
            <Category />
          </Suspense>
        }
      />
      <Route
        path="/cart"
        element={
          <Suspense fallback={null}>
            <CartPage />
          </Suspense>
        }
      />

      <Route
        path="/myshop"
        element={
          <Suspense fallback={null}>
            <MyPage />
          </Suspense>
        }
      >
        <Route path="/myshop/sales" element={<MyPage />} />
        <Route path="/myshop/purchase" element={<MyPage />} />
      </Route>

      <Route
        path="/search/"
        element={
          <Suspense fallback={null}>
            <Search />
          </Suspense>
        }
      />
      <Route
        path="/search/products"
        element={
          <Suspense fallback={null}>
            <SearchMatch />
          </Suspense>
        }
      />
      {/* footer nav routes */}
      <Route
        path="/policies/terms"
        element={
          <Suspense fallback={null}>
            <TermsOfService />
          </Suspense>
        }
      />
      <Route
        path="/recent-products/"
        element={
          <Suspense fallback={null}>
            <RecentlySaw />
          </Suspense>
        }
      />

      <Route
        path="/aboutus"
        element={
          <Suspense fallback={null}>
            <AboutUs />
          </Suspense>
        }
      />
      <Route
        path="/company-info"
        element={
          <Suspense fallback={null}>
            <CompanyInfo />
          </Suspense>
        }
      />
      <Route
        path="/policies/privacy"
        element={
          <Suspense fallback={null}>
            <PrivacyPolicy />
          </Suspense>
        }
      />
      <Route
        path="/store/:sellerId"
        element={
          <Suspense fallback={null}>
            <MiniShop />
          </Suspense>
        }
      />
      <Route
        path="/products/detail/:productId"
        element={
          <Suspense fallback={null}>
            <ProductDetailPage />
          </Suspense>
        }
      />
      <Route
        path="/products/:productId/qna/register"
        element={
          <Suspense fallback={null}>
            <RegisterBoardLayout title="상품문의작성" />
          </Suspense>
        }
      />
      <Route
        path="/new/search"
        element={
          <Suspense fallback={null}>
            <NewSearchResult />
          </Suspense>
        }
      />
      <Route
        path="/seller/:productId"
        element={
          <Suspense fallback={null}>
            <SellerInfo />
          </Suspense>
        }
      />
      <Route
        path="/reviews/register"
        element={
          <Suspense fallback={null}>
            <ProductReviewRegister />
          </Suspense>
        }
      />
      <Route
        path="/non-member-orders"
        element={
          <Suspense fallback={null}>
            <NonMemberOrderList />
          </Suspense>
        }
      />
      <Route
        path="/help/faq"
        element={
          <Suspense fallback={null}>
            <FaqPage />
          </Suspense>
        }
      />
      <Route
        path="/help/faq/:faqNo"
        element={
          <Suspense fallback={null}>
            <FaqDetailPage />
          </Suspense>
        }
      />
      <Route
        path="/help/fraud-cases"
        element={
          <Suspense fallback={null}>
            <FraudCasePage />
          </Suspense>
        }
      />
      <Route
        path="/help/seller-guide"
        element={
          <Suspense fallback={null}>
            <SellerGuidePage />
          </Suspense>
        }
      />
      <Route
        path="/change-pw-recommend"
        element={
          <Suspense fallback={null}>
            <NeedChangePassword />
          </Suspense>
        }
      />

      <Route
        path="/products/register"
        element={
          <Suspense fallback={null}>
            <ProductRegisterPage />
          </Suspense>
        }
      />
      <Route
        path="/products/register/:productId"
        element={
          <Suspense fallback={null}>
            <ProductRegisterPage />
          </Suspense>
        }
      />
      <Route
        path="/products/edit/:productId"
        element={
          <Suspense fallback={null}>
            <ProductEditPage />
          </Suspense>
        }
      />

      <Route
        path="/notify"
        element={
          <Suspense fallback={null}>
            <NotifyListPage />
          </Suspense>
        }
      />

      {/* 관련 path 묶어서 파일 분리한 것들 */}
      <Route path="/*" element={<TopNavRoutes />} />
      <Route path="talk/*" element={<TalkRoutes />} />
      <Route path="signup/*" element={<SignupRoutes />} />
      <Route path="signin/*" element={<SigninRoutes />} />
      <Route path="checkout/*" element={<CheckoutRoutes />} />
      <Route path="help/fake/*" element={<HelpFakeRoutes />} />
      <Route path="help/illegal-deal/*" element={<IllegalDealRoutes />} />
      <Route path="help/qna/*" element={<HelpQnaRoutes />} />
      <Route path="help/notice/*" element={<HelpNoticeRoutes />} />
      <Route path="help/report/*" element={<HelpReportRoutes />} />
      <Route path="find/*" element={<FindRoutes />} />
      <Route path="myshop/orders/*" element={<MyshopBuyTabRoutes />} />
      <Route path="myshop/purchases/*" element={<MyshopPurchasesRoutes />} />
      <Route path="myshop/sales/*" element={<MyshopSaleTabRoutes />} />
      <Route path="myshop/info/*" element={<MyshopInfoRoutes />} />
      <Route path="wishes/*" element={<WishesRoutes />} />
      <Route path="apply-cooperation/*" element={<ApplyCooperationRoutes />} />
      <Route element={<div>Not Found</div>} />
    </Routes>
  );
};
