/* eslint-disable no-param-reassign */
import { validateTokenTime } from "entities/user-info";
import { isKeepLogin, keepLoginApi } from "features/auth/keep-login";
import { refreshTokenApi } from "features/auth/refresh-token";
import { handleSignOutAndRedirect } from "features/auth/sign-out";
import { api } from "shared/constants/api";
import { ERROR_CODE } from "shared/constants/errorCode";
import { showAlert } from "shared/lib";
import type { APIAxiosError } from "shared/types/server";

type Reject = (reason?: unknown) => void;

/** 인증 관련 URL 여부 확인 */
export const isAuthRelatedUrl = (url = "") =>
  [
    api.users.refresh,
    api.users.logout,
    api.users.keepLogin,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ].includes(url as any);

/** axios Response 재시도 횟수 및 딜레이 업데이트 함수 */
export const updateRetryConfig = (error: APIAxiosError) => {
  const { currentRetries = 0, currentDelay = 1000 } = error.config;

  error.config.currentRetries = currentRetries + 1;
  error.config.currentDelay = currentDelay * 2;

  return { currentRetries, currentDelay };
};

/** axios Response 에러 처리 함수 */
export const errorHandlers = {
  /** 📌 중복 요청 에러 처리 */
  duplicateRequest: (error: APIAxiosError) => {
    const isDuplicateRequestError = String(error).includes(
      ERROR_CODE.common.DuplicateRequest
    );

    if (isDuplicateRequestError) {
      return Promise.reject(error);
    }

    return null;
  },

  /** 📌 재시도 횟수 초과 에러 처리 */
  overRetries: (error: APIAxiosError, currentRetries: number) => {
    const RETRY_COUNT = 2;
    const isOverRetries = currentRetries > RETRY_COUNT;

    if (isOverRetries) {
      return Promise.reject(error);
    }

    return null;
  },

  /** 📌 네트워크 에러 처리 */
  networkError: (error: APIAxiosError) => {
    const isNetworkError =
      error.message.includes("timeout") || error.code === "ECONNABORTED";

    if (isNetworkError) {
      showAlert("네트워크 연결에 실패하였습니다.", {
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          window.location.href = "/";
        }
      });
    }

    return isNetworkError;
  },

  /** 📌 토큰 갱신 관련 에러 시 로그아웃 처리 (리프레시 토큰, 로그인유지) */
  tokenRenewalError: (error: APIAxiosError) => {
    const isTokenRenewalRequest = [
      api.users.refresh,
      api.users.keepLogin,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ].includes(String(error.config.url) as any);

    if (isTokenRenewalRequest) {
      handleSignOutAndRedirect(error.response?.data.message);
    }

    return isTokenRenewalRequest;
  },

  /** 📌 인증 에러 처리 */
  authError: async (
    error: APIAxiosError,
    reject: Reject,
    authErrorCount: number
  ) => {
    if (authErrorCount > 1) {
      return reject(error);
    }

    // 리프레시 토큰 만료 시 리프레시 토큰 갱신
    if (
      validateTokenTime("refreshToken") &&
      !validateTokenTime("accessToken")
    ) {
      await refreshTokenApi.refreshToken();
      return reject(error);
    }

    // 로그인 유지 체크 시 로그인 유지 요청
    if (isKeepLogin()) {
      await keepLoginApi.keepLogin();
      return reject(error);
    }

    // 로그아웃 하기
    handleSignOutAndRedirect();
    return reject(error);
  },
} as const;
